import React, { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import type { Value as PhoneNumberValue, Country as CountryCode } from 'react-phone-number-input';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Input from 'components/commercetools-ui/atoms/input';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useCountries } from 'providers/countries';
import type { Fields, FieldsOptions } from './types';
import type { Address } from '../../types';

interface Props {
    className?: string;
    address: Address;
    children?: React.ReactNode;
    phone?: PhoneNumberValue;
    fields: (options: FieldsOptions) => Fields[];
    onChange?: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
    onBlur?: (e: React.ChangeEvent<any>) => void;
    onSubmit?: () => void;
}

const AddressForm: React.FC<Props> = ({ className: containerClassName, fields, address, phone, children, onChange, onBlur, onSubmit }) => {
    const [enableAddress2, setEnableAddress2] = useState(false);
    const [phoneValue, setPhoneValue] = useState<PhoneNumberValue>(phone ?? '');

    const { countryCodes } = useCountries();

    const phoneCountries = countryCodes as CountryCode[];

    const onEnableAddress2 = useCallback(() => setEnableAddress2(true), []);

    const handleSubmit = useCallback(
        (e: React.FormEvent) => {
            e.preventDefault();
            onSubmit?.();
        },
        [onSubmit]
    );

    const renderElement: (data: Fields) => JSX.Element = ({
        name,
        label,
        labelDesc,
        type,
        required,
        defaultValue,
        maxLength,
        errorMessage,
        validate,
        render,
        items
    }) => {
        if (type === 'dropdown') {
            if (!items?.length) return <></>;
            return (
                <Dropdown
                    name={name}
                    items={items}
                    label={label}
                    errorMessage={errorMessage}
                    required={required}
                    defaultValue={defaultValue}
                    onChange={onChange}
                />
            );
        }

        if (type === 'phone') {
            return (
                <div data-error={!!errorMessage}>
                    <label htmlFor={name} className="mb-8 pb-2 text-left font-body text-14 font-label leading-loose">
                        {label}
                    </label>
                    <PhoneInput
                        maxLength={maxLength}
                        name={name}
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="GB"
                        value={phoneValue}
                        countries={phoneCountries}
                        required={required}
                        className={errorMessage && 'error'}
                        onChange={e => {
                            onChange?.({
                                target: { name, value: e ?? '' }
                            } as any);
                            setPhoneValue(e!);
                        }}
                        onBlur={onBlur}
                    />
                    {errorMessage && (
                        <Typography className="mt-12 text-sm text-input-error" as="p">
                            {errorMessage}
                        </Typography>
                    )}
                </div>
            );
        }
        return (
            <>
                <Input
                    name={name}
                    label={label}
                    labelDesc={labelDesc}
                    type={type}
                    required={required}
                    value={address[name as keyof Address]}
                    labelPosition="top"
                    isValid={
                        (!required || (required && !!address[name as keyof Address])) &&
                        (validate ? validate(address[name as keyof Address]!) : true)
                    }
                    onChange={onChange}
                    onBlur={onBlur}
                    errorMessage={errorMessage}
                    hideCheckIcon
                />
                {render?.()}
            </>
        );
    };

    return (
        <form onSubmit={handleSubmit} data-cy="add-new-address-form" noValidate={true}>
            <div className={`grid grid-cols-3 gap-12 ${containerClassName}`}>
                {fields({ enableAddress2, onEnableAddress2 }).map(data => (
                    <React.Fragment key={data.name}>
                        <div className={data.className}>{renderElement(data)}</div>
                    </React.Fragment>
                ))}
            </div>
            {children}
        </form>
    );
};

export default AddressForm;
