import type { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import SummaryAccordion from './components/summary-accordion';
import type { OrderSummaryProps } from './types';
import OrderItemsListing from '../order-items-listing';
import OrderPaymentSection from '../order-payment-section';
import Costs from '../order-payment-section/components/costs';
import DiscountForm from '../order-payment-section/components/discount-form';

const OrderSummary: FC<OrderSummaryProps> = ({
    title,
    className,
    includeLoginSuggestion,
    includeSummaryAccordion,
    dataReference,
    paymentMethods,
    includeItemsList,
    isThanksPage,
    isCartPage,
    asSkeleton,
    hasOutOfStockItems,
    ...props
}) => {
    const [isDesktopSize] = useMediaQuery(desktop);
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

    const itemsListClassName = useClassNames([props.classNames?.itemsList]);
    const lineItems = dataReference === 'cart' ? props.cart?.lineItems ?? [] : props.order?.lineItems;

    if (asSkeleton && !isCartPage) {
        return (
            <div className={className}>
                <div className="border-checkout-border mb-16 py-16 lg:border-b lg:pt-0">
                    <Skeleton className="h-30 w-full" />
                </div>
                <div className="border-checkout-border mb-16 py-16 lg:border-b lg:pt-0">
                    <Skeleton className="h-80 w-full" />
                </div>
                <Skeleton className="h-30" />
            </div>
        );
    }

    return (
        <div className={className}>
            {(title ?? includeLoginSuggestion) && (
                <div className="border-checkout-border py-16 lg:border-b lg:pt-0">
                    {title && (
                        <Typography className="text-16 md:text-18" as="h4">
                            {title}
                        </Typography>
                    )}
                </div>
            )}
            {isDesktopSize && includeItemsList && lineItems && (
                <OrderItemsListing className={itemsListClassName} lineItems={lineItems} isThanksPage={isThanksPage} />
            )}
            {!isDesktopSize && includeSummaryAccordion && <SummaryAccordion className="lg:mt-40" order={props.order} cart={props.cart} />}
            {!isDesktopSize && includeSummaryAccordion && !isThanksPage && (
                <DiscountForm className={props.classNames?.applyDiscountButton} />
            )}
            {!isDesktopSize && includeSummaryAccordion && <Costs className="pb-24" order={props.order} dataReference={dataReference} />}
            {(isDesktopSize || !includeSummaryAccordion) && (
                <OrderPaymentSection
                    isMiniCart={false}
                    isCartPage={isCartPage}
                    classNames={{
                        applyDiscountButton: 'py-14 text-16',
                        totalAmount: 'text-18 md:pb-20',
                        subCostsContainer: 'pt-12 md:pt-16 mb-20 lg:py-24 lg:mb-16 lg:border-b border-checkout-border'
                    }}
                    paymentMethods={paymentMethods}
                    dataReference={dataReference}
                    hasOutOfStockItems={hasOutOfStockItems}
                    {...props}
                />
            )}
            {!isDesktopSize && includeSummaryAccordion && !isThanksPage && props.button}
            {!isDesktopSize && includeSummaryAccordion && !isThanksPage && hasOutOfStockItems && (
                <Typography className="mt-8 text-14 text-red-500">
                    {formatCartMessage({ id: 'cart.outOfStock', defaultMessage: 'Some items are out of stock' })}
                </Typography>
            )}
        </div>
    );
};

export default OrderSummary;
