import React, { useState, useCallback, useEffect } from 'react';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import LoadingIcon from 'components/commercetools-ui/atoms/button/loadingIcon';
import Input from 'components/commercetools-ui/atoms/input';
import Overlay from 'components/commercetools-ui/atoms/overlay';
import useResolveCCImage from 'components/commercetools-ui/organisms/checkout/hooks/useResolveCCImage';
import { useCheckout } from 'components/commercetools-ui/organisms/checkout/provider';
import type { PaymentData, CreditCardData } from 'components/commercetools-ui/organisms/checkout/provider/payment/types';
import { useFormat } from 'helpers/hooks/useFormat';
import scrollToError from 'helpers/utils/scrollToError';

interface Props {
    submitForm: boolean;
    onValid: (token: string) => void;
    onInvalid: () => void;
    currency: string;
}
const Fields: React.FC<Props> = ({ submitForm, onInvalid, onValid, currency }) => {
    const resolveCCImage = useResolveCCImage();
    const { formatMessage } = useFormat({ name: 'payment' });

    const [paymentIsLoading, setPaymentIsLoading] = useState(false);
    const [cardNumberInvalid, setCardNumberInvalid] = useState(false);
    const [securityCodeInvalid, setSecurityCodeInvalid] = useState(false);
    const [expiryInvalid, setExpiryInvalid] = useState(false);
    const [nameOnCardInvalid, setNameOnCardInvalid] = useState(false);
    const [cardHolderName, setCardHolderName] = useState<string>('');

    const { paymentData, setPaymentData, createPaymentToGetTokenContext, microform, cardType } = useCheckout();

    useEffect(() => {
        if (submitForm) {
            validateFields();
        }

        if (nameOnCardInvalid || expiryInvalid || securityCodeInvalid || cardNumberInvalid) {
            scrollToError();
        }
    }, [submitForm, nameOnCardInvalid, expiryInvalid, securityCodeInvalid, cardNumberInvalid]);

    function validateFields() {
        if (paymentData.type !== PaymentMethodType.CARD) return;
        setCardNumberInvalid(false);
        setSecurityCodeInvalid(false);
        setExpiryInvalid(false);
        setNameOnCardInvalid(false);
        let allValid = true;

        if (!paymentData?.holderName?.match(/^[a-z ,.'-]+$/i) || paymentData?.holderName?.trim().split(' ').length < 2) {
            setNameOnCardInvalid(true);
            allValid = false;
            onInvalid();
        }
        if (
            !(paymentData.expiryMonth && paymentData.expiryYear) ||
            +paymentData.expiryYear < new Date().getFullYear() ||
            +paymentData.expiryYear >= new Date().getFullYear() + 10 ||
            (+paymentData.expiryYear == new Date().getFullYear() && +paymentData.expiryMonth < new Date().getMonth() + 1)
        ) {
            setExpiryInvalid(true);
            allValid = false;
            onInvalid();
        }
        if (paymentData.expiryMonth && paymentData.expiryYear && allValid) {
            setPaymentIsLoading(true);

            const options = {
                expirationMonth: paymentData.expiryMonth,
                expirationYear: paymentData.expiryYear
            };

            microform?.createToken(options, function (err, token) {
                if (err) {
                    switch (err.reason) {
                        case 'CREATE_TOKEN_NO_FIELDS_LOADED':
                        case 'CREATE_TOKEN_TIMEOUT':
                        case 'CREATE_TOKEN_UNABLE_TO_START':
                        case 'CREATE_TOKEN_NO_FIELDS':
                        case 'CREATE_TOKEN_CAPTURE_CONTEXT_USED_TOO_MANY_TIMES':
                            console.log('<<<microform CREATE_TOKEN_CAPTURE_CONTEXT_USED_TOO_MANY_TIMES currency', currency);
                            createPaymentToGetTokenContext(onInvalid, true, currency);
                            break;
                        case 'CREATE_TOKEN_VALIDATION_PARAMS':
                        case 'CREATE_TOKEN_VALIDATION_FIELDS':
                        case 'CREATE_TOKEN_VALIDATION_SERVERSIDE':
                            onInvalid();
                            if (err?.details && Array.isArray(err.details) && err.details.length > 0) {
                                err.details.forEach(errorDetail => {
                                    if (errorDetail.location === 'number') {
                                        setCardNumberInvalid(true);
                                    }
                                    if (errorDetail.location === 'securityCode') {
                                        setSecurityCodeInvalid(true);
                                    }
                                    if (errorDetail.location === 'expirationMonth' || errorDetail.location === 'expirationYear') {
                                        setExpiryInvalid(true);
                                    }
                                });
                            } else {
                                console.log('<<<microform CREATE_TOKEN_CAPTURE_CONTEXT_USED_TOO_MANY_TIMES else', currency);
                                createPaymentToGetTokenContext(onInvalid, true, currency);
                            }
                            break;
                        default:
                            //unknown error
                            break;
                    }
                    setPaymentIsLoading(false);
                    console.error(err);
                } else {
                    setExpiryInvalid(false);
                    setPaymentData({
                        ...paymentData,
                        paymentCardType: cardType,
                        token,
                        // paymentMethod,
                        tokenizeCard: true,
                        withToken: false
                    } as CreditCardData);

                    setPaymentIsLoading(false);

                    onValid(token);
                }
            });
        }
    }
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            // the substring does not allow the user to fill more than 50 chars
            const name = e.target.value.substring(0, 49);
            setCardHolderName(name);
            setPaymentData({ ...paymentData, [e.target.name as keyof PaymentData]: name } as CreditCardData);
        },
        [paymentData, setPaymentData]
    );

    const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.match(/[^\d]/g)) {
            e.target.value = '';
        }
        if (e.target.value.length > 2) {
            e.target.value = e.target.value.substring(0, 2);
        }
        if (+e.target.value > 12) {
            e.target.value = '';
        }

        setPaymentData({ ...paymentData, expiryMonth: e.target.value } as CreditCardData);
    };
    const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target?.value?.match(/[^\d]/g)) {
            e.target.value = '';
        }
        if (e.target.value.length > 2) {
            e.target.value = e.target.value.substring(0, 2);
        }

        const fullYear = '20' + e.target.value;

        setPaymentData({ ...paymentData, expiryYear: fullYear.length === 4 ? fullYear : null } as CreditCardData);
    };

    const loader = () => {
        return (
            <>
                <Overlay />
                <div
                    style={{ width: '50px', height: '50px' }}
                    className="fixed left-1/2 top-1/2 z-[999] -translate-x-1/2 -translate-y-1/2 bg-white"
                >
                    <div className="fixed left-1/2 top-1/2 z-[999] -translate-x-1/2 -translate-y-1/2 bg-white">
                        <LoadingIcon className="fill-gray-700" />
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {paymentIsLoading && loader()}
            <div data-error={nameOnCardInvalid}>
                <Input
                    label={formatMessage({ id: 'card.holder', defaultMessage: 'Card holder' })}
                    labelPosition="top"
                    name="holderName"
                    data-cy="cs-form-holder-name"
                    className={` ${nameOnCardInvalid ? 'border-input-error' : ''} text-14 placeholder:text-secondary-black sm:px-8`}
                    placeholder={formatMessage({ id: 'card.holder.placeholder', defaultMessage: 'First and Last name' })}
                    onChange={handleChange}
                    value={cardHolderName}
                />
                {nameOnCardInvalid && (
                    <p className="mt-12 text-left font-body text-sm leading-tight text-input-error">
                        {formatMessage({
                            id: 'card.invalid.securityNumber',
                            defaultMessage: 'Enter your First and Last name'
                        })}
                    </p>
                )}
            </div>
            <div className="mb-8 mt-16">
                <label className="text-left font-body text-14 font-label leading-loose">
                    {formatMessage({ id: 'card.number', defaultMessage: 'Card number' })}
                </label>
            </div>
            <div className="relative" data-error={cardNumberInvalid}>
                <div
                    id="number-container"
                    data-cy="cs-form-number-container"
                    // eslint-disable-next-line tailwindcss/no-contradicting-classname

                    className={` ${
                        cardNumberInvalid ? 'border-input-error' : ''
                    } h-45 w-full rounded-md border border-input-border bg-input-bg px-12 py-10 text-14 text-primary-black placeholder:text-14 placeholder:leading-normal placeholder:text-secondary-black focus:border-input-border focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-400 sm:px-8`}
                ></div>
                {resolveCCImage(cardType) && (
                    // eslint-disable-next-line
                    <img className="absolute right-8 top-1/2 w-[32px] -translate-y-1/2" src={resolveCCImage(cardType)} alt={cardType} />
                )}
            </div>
            {cardNumberInvalid && (
                <p className="mt-12  text-left font-body text-sm leading-tight text-input-error">
                    {formatMessage({ id: 'card.invalid.securityNumber', defaultMessage: 'Please enter a valid value' })}
                </p>
            )}

            <div className="mt-16 gap-8 sm:flex" data-error={expiryInvalid}>
                <div className="flex gap-8  sm:flex-1">
                    <div className="absolute">
                        <label htmlFor="expiryMonth" className="text-md font-label leading-loose">
                            {formatMessage({ id: 'card.exp.month', defaultMessage: 'Expiration date *' })}
                        </label>
                    </div>
                    <div className="grow sm:flex-1">
                        <Input
                            name="expiryMonth"
                            data-cy="cs-form-exp-month"
                            className={` ${expiryInvalid ? 'border-input-error' : ''} mt-32 text-14 placeholder:text-secondary-black sm:px-8`}
                            placeholder={formatMessage({ id: 'card.exp.month.sign', defaultMessage: 'mm' })}
                            onChange={handleMonthChange}
                        />
                    </div>
                    <div className="pt-24" style={{ lineHeight: '54px' }}>
                        /
                    </div>
                    <div className="grow sm:flex-1">
                        <Input
                            name="expiryYear"
                            data-cy="cs-form-exp-year"
                            className={` ${expiryInvalid ? 'border-input-error' : ''} mt-32 text-14 placeholder:text-secondary-black  sm:px-8`}
                            placeholder={formatMessage({ id: 'card.exp.year.sign', defaultMessage: 'yy' })}
                            onChange={handleYearChange}
                        />
                    </div>
                </div>

                <div className="grow sm:max-w-135" data-error={securityCodeInvalid}>
                    <div className="m-8 sm:mt-0">
                        <label className="text-left font-body text-14 font-label leading-loose">
                            {formatMessage({ id: 'card.securityNumber', defaultMessage: 'Security number' })}
                        </label>
                    </div>
                    <div
                        id="securityCode-container"
                        data-cy="cs-form-csv-container"
                        // eslint-disable-next-line tailwindcss/no-contradicting-classname
                        className={` ${
                            securityCodeInvalid ? 'border-input-error' : ''
                        } h-45 w-full rounded-md border border-input-border bg-input-bg px-12 py-10 text-14 text-primary-black placeholder:text-14 placeholder:leading-normal placeholder:text-secondary-black focus:border-input-border focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-400 sm:px-8`}
                    ></div>
                    {securityCodeInvalid && (
                        <p className="mt-12 text-left font-body text-sm leading-tight text-input-error">
                            {formatMessage({
                                id: 'card.invalid.securityNumber',
                                defaultMessage: 'Enter valid Security code'
                            })}
                        </p>
                    )}
                </div>
            </div>
            {expiryInvalid && (
                <p className="mt-12 text-left font-body text-sm leading-tight text-input-error">
                    {formatMessage({ id: 'card.invalid.expiration', defaultMessage: 'Enter valid expiration date' })}
                </p>
            )}
        </>
    );
};
export default Fields;
