import React, { useState, useCallback, useEffect } from 'react';
import Script from 'next/script';
import { sdk } from 'sdk';
import type { LoqateSettings } from 'frontastic/hooks/useProjectSettings/types';

const Loqate = () => {
    const [loqateSettings, setLoqateSettings] = useState<LoqateSettings>({});

    const getLoqateSettings = useCallback(async () => {
        const res = await sdk.callAction({ actionName: 'project/getLoqateSettings' });

        if (res.isError === true || !res.data) {
            setLoqateSettings({});
        } else {
            const data: LoqateSettings = {
                data: res.data
            };
            setLoqateSettings(data);
        }
    }, []);

    useEffect(() => {
        void getLoqateSettings();
    }, []);

    if (loqateSettings?.data?.enabled && loqateSettings?.data?.accountCode && loqateSettings?.data?.host) {
        return (
            <Script id="loqate">
                {`
                    (function(n,t,i,r){var u,f;n[i]=n[i]||{},n[i].initial={accountCode:"${loqateSettings.data.accountCode}",host:"${loqateSettings.data.host}"},n[i].on=n[i].on||function(){(n[i].onq=n[i].onq||[]).push(arguments)},u=t.createElement("script"),u.async=!0,u.src=r,f=t.getElementsByTagName("script")[0],f.parentNode.insertBefore(u,f)})(window,document,"pca","//${loqateSettings.data.host}/js/sensor.js")
                `}
            </Script>
        );
    } else {
        return <></>;
    }
};

export default Loqate;
