import React, { useContext, useMemo } from 'react';
import CybersourcePaymentProvider, { CybersourceContext } from './payment/cybersource';

const CheckoutContext = React.createContext({});

interface CheckoutProviderProps {
    children?: React.ReactNode;
}

const CheckoutProvider: React.FC<CheckoutProviderProps> = ({ children }) => {
    const obj = useMemo(() => ({}), []);
    return (
        <CheckoutContext.Provider value={obj}>
            <CybersourcePaymentProvider>{children}</CybersourcePaymentProvider>
        </CheckoutContext.Provider>
    );
};

export default CheckoutProvider;

export const useCheckout = () => useContext(CybersourceContext);
