import type { FC } from 'react';
import React from 'react';
import { useRouter } from 'next/navigation';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useBrandSettingsContext } from 'providers/brand-settings';
import Markdown from '../../../markdown';

export interface Props {
    isErrorModalOpen: boolean;
    setIsErrorModalOpen: (val: boolean) => void;
    customError?: string;
}
const ErrorModal: FC<Props> = ({ isErrorModalOpen, setIsErrorModalOpen, customError }) => {
    const router = useRouter();

    const closeRedirectModal = () => {
        setIsErrorModalOpen(false);
        router.replace('/');
    };

    const { errorModalTitle, errorModalContent, errorModalButtonText, errorModalSecondButtonText } = useBrandSettingsContext();

    return (
        <Modal
            isOpen={isErrorModalOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '400px' } }}
            preventScroll={true}
        >
            <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                {customError ? (
                    <p className="mb-10 text-center text-20 font-bold">{customError}</p>
                ) : (
                    <>
                        <Typography as="h3" className="text-center text-20 font-medium text-primary-black">
                            {errorModalTitle}
                        </Typography>
                        <Markdown markdown={errorModalContent ?? ''} className="markdown" />{' '}
                    </>
                )}

                <Button
                    variant="warning"
                    size="full"
                    onClick={() => {
                        setIsErrorModalOpen(false);
                    }}
                    className="rounded-md md:rounded-lg"
                    data-cy="error-modal-second-button"
                >
                    {errorModalSecondButtonText}
                </Button>
                <Button variant="warning" size="full" onClick={closeRedirectModal} className="rounded-md md:rounded-lg">
                    {errorModalButtonText}
                </Button>
            </div>
        </Modal>
    );
};

export default ErrorModal;
