import type { SDKResponse } from '@commercetools/frontend-sdk';
import useSWR from 'swr';
import { sdk } from 'sdk';
import type { StudioProjectSettingsForPayments } from './types';

const useStudioPaymentsSettings = () => {
    const response = useSWR<SDKResponse<StudioProjectSettingsForPayments>>('action/project/getStudioProjectSettingsForPayments', () =>
        sdk.callAction<StudioProjectSettingsForPayments>({ actionName: 'project/getStudioProjectSettingsForPayments' })
    );

    const data = (response.data?.isError ? {} : response.data?.data) as StudioProjectSettingsForPayments;

    const invoiceEnabled = data?.invoiceEnabled ?? false;
    const creditCardEnabled = data?.creditCardEnabled ?? false;
    const instalmentsEnabled = data?.instalmentsEnabled ?? false;
    const cardinalOrigin = data?.cardinalOrigin ?? '';
    const instalmentRenewalMessage = data?.instalmentRenewalMessage ?? '';
    const unavailableInvoiceRenewalMessage = data?.unavailableInvoiceRenewalMessage ?? '';
    const tokenizeEarlyAndUseTokensOnly = data?.tokenizeEarlyAndUseTokensOnly ?? '';
    const earlyTokenizationExclusions = data?.earlyTokenizationExclusions.split(',').map(currency => currency.trim().toUpperCase()) ?? [];
    const flexFormLink = data?.flexFormLink ?? '';

    return {
        loading: response.isLoading,
        paymentSettings: data,
        invoiceEnabled,
        instalmentsEnabled,
        creditCardEnabled,
        cardinalOrigin,
        instalmentRenewalMessage,
        unavailableInvoiceRenewalMessage,
        tokenizeEarlyAndUseTokensOnly,
        earlyTokenizationExclusions,
        flexFormLink
    };
};

export default useStudioPaymentsSettings;
