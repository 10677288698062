import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import { useFormat } from 'helpers/hooks/useFormat';

export interface Props {
    isDiscountErrorModalOpen: boolean;
}

const DiscountErrorModal: FC<Props> = ({ isDiscountErrorModalOpen }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { formatMessage: formatCartMessage } = useFormat({ name: 'checkout' });

    useEffect(() => {
        setIsModalOpen(isDiscountErrorModalOpen);
    }, [isDiscountErrorModalOpen]);

    return (
        <Modal
            isOpen={isModalOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '400px' } }}
            preventScroll={true}
        >
            <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                <p className="text-center">
                    {formatCartMessage({
                        id: 'discount.error.modal.text',
                        defaultMessage:
                            'The applied discount code does not match the cart. To place the order you should remove the invlaid code'
                    })}
                </p>

                <Button
                    variant="warning"
                    size="full"
                    onClick={() => {
                        setIsModalOpen(false);
                    }}
                    className="rounded-md md:rounded-lg"
                >
                    {formatCartMessage({
                        id: 'discount.error.modal.button',
                        defaultMessage: 'I understand'
                    })}
                </Button>
            </div>
        </Modal>
    );
};

export default DiscountErrorModal;
