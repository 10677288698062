import { useCallback } from 'react';
import type { Address as AccountAddress } from '@wilm/shared-types/account';
import { useAccount } from 'frontastic';
import type { Address } from '../types';

const useMappers = () => {
    const { account } = useAccount();

    const accountAddressToAddress = useCallback(
        (address: AccountAddress) => {
            return {
                addressId: address.addressId,
                firstName: address.firstName,
                lastName: address.lastName,
                email: account?.email ?? '',
                phone: address.phone,
                line1: address.streetName ?? '',
                line2: address.additionalAddressInfo,
                postalCode: address.postalCode ?? '',
                city: address.city ?? '',
                country: address.country ?? '',
                region: address.region ?? '',
                type: address.isBillingAddress ? 'billing' : 'shipping',
                isDefault: address.isDefaultBillingAddress ?? address.isDefaultShippingAddress
            } as Address;
        },
        [account]
    );

    const addressToAccountAddress = useCallback((address: Address) => {
        return {
            firstName: address.firstName ?? '',
            lastName: address.lastName ?? '',
            phone: address.phone,
            streetName: address.line1,
            additionalAddressInfo: address.line2,
            postalCode: address.postalCode,
            city: address.city,
            country: address.country,
            email: address.email,
            addressId: address.addressId,
            region: address.region
            // isDefault: address.isDefaultBillingAddress ?? address.isDefaultShippingAddress
        } as AccountAddress;
    }, []);

    return { addressToAccountAddress, accountAddressToAddress };
};

export default useMappers;
