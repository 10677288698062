import React from 'react';

type Props = {
    className?: string;
};

const EditIcon: React.FC<Props> = ({ className }: Props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <g id="Frame" clipPath="url(#clip0_6196_1797)">
            <path
                id="Vector"
                d="M14.6826 2.45571L15.0844 2.85747C15.3965 3.16958 15.3965 3.67427 15.0844 3.98306L14.1182 4.95259L12.5875 3.42192L13.5537 2.45571C13.8658 2.1436 14.3705 2.1436 14.6793 2.45571H14.6826ZM7.00605 9.00669L11.4619 4.54751L12.9926 6.07817L8.5334 10.534C8.43711 10.6303 8.31758 10.7 8.18808 10.7366L6.2457 11.2911L6.80019 9.34868C6.83672 9.21919 6.90644 9.09966 7.00273 9.00337L7.00605 9.00669ZM12.4281 1.33013L5.87715 7.87778C5.58828 8.16665 5.3791 8.52192 5.26953 8.9104L4.31992 12.2307C4.24023 12.5096 4.3166 12.8084 4.52246 13.0143C4.72832 13.2202 5.02715 13.2965 5.30605 13.2168L8.62637 12.2672C9.01816 12.1543 9.37344 11.9452 9.65898 11.6596L16.21 5.11196C17.143 4.17895 17.143 2.66489 16.21 1.73188L15.8082 1.33013C14.8752 0.397119 13.3611 0.397119 12.4281 1.33013ZM2.96191 2.62505C1.34824 2.62505 0.0400391 3.93325 0.0400391 5.54692V14.5782C0.0400391 16.1918 1.34824 17.5 2.96191 17.5H11.9932C13.6068 17.5 14.915 16.1918 14.915 14.5782V10.8594C14.915 10.4178 14.5598 10.0625 14.1182 10.0625C13.6766 10.0625 13.3213 10.4178 13.3213 10.8594V14.5782C13.3213 15.312 12.727 15.9063 11.9932 15.9063H2.96191C2.22812 15.9063 1.63379 15.312 1.63379 14.5782V5.54692C1.63379 4.81313 2.22812 4.2188 2.96191 4.2188H6.68066C7.12226 4.2188 7.47754 3.86352 7.47754 3.42192C7.47754 2.98032 7.12226 2.62505 6.68066 2.62505H2.96191Z"
                fill="#414141"
            />
        </g>
        <defs>
            <clipPath id="clip0_6196_1797">
                <rect width="17" height="17" fill="white" transform="translate(0.0400391 0.5)" />
            </clipPath>
        </defs>
    </svg>
);

export default EditIcon;
