export const valueOrNull = (value: string | undefined) => {
    return value?.length ? value : null;
};

export const valueOrEmpty = (value: string | undefined | null) => {
    return value ?? '';
};

/**
 * Checks if given value is empty (=== '', === null, === undefined).
 */
export const isEmpty = (value: unknown): boolean => value === '' || value === null || value === undefined;

/**
 * Checks if given value is not empty (!== '', !== null, !== undefined).
 */
export const isNotEmpty = (value: unknown): boolean => value !== '' && value !== null && value !== undefined;
